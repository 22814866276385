import React, { useState } from 'react';
import styled from '@emotion/styled';
import { FaSignInAlt } from 'react-icons/fa';

import { Button, Modal } from '../atoms';
import { dimensions } from '../../styles/variables';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${dimensions.space.base};
`;

export const BROKER_SPACE_URL = 'https://tarificateur.looma-assurances.fr/?action=front-login';
export const CLIENT_SPACE_URL = 'https://www.sgsante.fr/';

export default function LoginButtonAndModal() {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Button
        onClick={() => setModalOpen(true)}
        size="medium"
        color="gold"
        variant="rectangular"
        leftIcon={<FaSignInAlt />}
      >
        Se connecter
      </Button>
      <Modal show={modalOpen} onClose={() => setModalOpen(false)} title="Se connecter">
        <Container>
          <Button href={BROKER_SPACE_URL} target="_blank">
            Espace courtiers
          </Button>
          <Button href={CLIENT_SPACE_URL} target="_blank">
            Espace assurés
          </Button>
        </Container>
      </Modal>
    </>
  );
}
