import styled from '@emotion/styled';
import { breakpoints, dimensions } from '../../styles/variables';

const PageWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: ${dimensions.space.base};
  padding-right: ${dimensions.space.base};
  max-width: ${breakpoints.xl};
`;

export default PageWrapper;
