import styled from '@emotion/styled';
import React, { useRef } from 'react';
import { Button, Modal } from '../atoms';
import logo from '../../images/logo.png';
import { widths } from '../../styles/variables';

const Description = styled.p`
  margin-bottom: 24px;
`;

const Textinput = styled.input`
  font: inherit;
  display: block;
  width: 100%;
  padding: 12px 18px;
  border: 2px solid #b8b8b8;
  border-radius: 5px;
  margin-top: 12px;
`;

const SubmitButton = styled(Button)`
  margin-top: 21px;
  margin-left: auto;
`;

const Header = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-size: 33px;
  line-height: 40px;
  font-weight: normal;
`;

export type JoinUsModalProps = {
  show: boolean;
  onClose();
};

export default function JoinUsModal({ show, onClose }: JoinUsModalProps) {
  const firstName = useRef<HTMLInputElement>();
  const lastName = useRef<HTMLInputElement>();
  const email = useRef<HTMLInputElement>();
  const phone = useRef<HTMLInputElement>();

  return (
    <Modal show={show} onClose={onClose}>
      <Header>
        Rejoignez
        <img alt="logo" src={logo} width={widths.sm} />
      </Header>
      <Description>
        Une aventure humaine avec une équipe enthousiaste et créative, ça vous tente ? Il n’y a qu’un pas à franchir !
      </Description>
      <form
        onSubmit={(e) => {
          window.open(
            `mailto:nullepart@looma.fr?subject=${encodeURIComponent(
              'Candidature',
            )}&body=${encodeURIComponent(`Je postule ! Mes coordonnées :

          Prénom: ${firstName.current.value}
          Nom: ${lastName.current.value}
          Email: ${email.current.value}
          Numéro de téléphone: ${phone.current.value}
          `)}`,
          );
          e.preventDefault();
        }}
      >
        <Textinput type="text" required placeholder="Votre nom" ref={firstName} />
        <Textinput type="text" required placeholder="Votre prénom" ref={lastName} />
        <Textinput type="email" required placeholder="Votre email" ref={email} />
        <Textinput type="text" required placeholder="Numéro de téléphone" ref={phone} />
        <SubmitButton type="submit">Postulez !</SubmitButton>
      </form>
    </Modal>
  );
}
