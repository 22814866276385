import styled from '@emotion/styled';

import { breakpoints, colors, dimensions, variants } from '../../styles/variables';

const Title = styled.h1<{ variant?: string }>`
  color: ${(props) => (props.variant === 'dark' ? colors.black : colors.white)};
  font-weight: ${variants.bold};
  margin-bottom: 0;
  margin-top: 0;
  font-size: 32px;
  line-height: 39px;
  @media screen and (min-width: ${breakpoints.md}) {
    font-size: ${dimensions.text.h1}px;
    line-height: ${dimensions.lineHeight.h1}px;
  }
`;

export default Title;
