import React from 'react';
import styled from '@emotion/styled';

import { colors, dimensions, variants } from '../../styles/variables';
import RenderMarkdown from './RenderMarkdown';

const H2 = styled.h2<{ variant?: string }>`
  font-size: ${dimensions.text.h2}px;
  line-height: ${dimensions.lineHeight.h2}px;
  font-weight: ${variants.medium};
  text-transform: uppercase;
  color: ${(props) => (props.variant === 'dark' ? colors.purple : colors.white)};
  opacity: ${(props) => (props.variant === 'dark' ? 1 : 0.5)};
  margin-bottom: ${dimensions.space.twelve};
  margin-top: 0;
  position: relative;
`;

type Props = React.HTMLAttributes<HTMLHeadingElement> & {
  children: string;
  variant: string;
};

export default function PreTitle({ children, variant, style }: Props) {
  return (
    <H2 variant={variant} style={style}>
      <RenderMarkdown disableParagraph>{children}</RenderMarkdown>
    </H2>
  );
}
