import styled from '@emotion/styled';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { FaBars } from 'react-icons/fa';
import { IoCloseSharp } from 'react-icons/io5';
import { HeaderItem, Logo } from '../atoms';
import { breakpoints, colors, dimensions } from '../../styles/variables';
import LoginButtonAndModal from './LoginButtonAndModal';
import { useBrokerModal } from './BrokerModal';

const Overlay = styled.div<{ open: boolean }>`
  background-color: ${colors.purple};
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: ${({ open }) => (open ? '1000' : '-1')};
  opacity: ${({ open }) => (open ? '0.7' : '0')};
  transition: opacity 0.25s ease-in-out;
  pointer-events: ${({ open }) => (open ? 'all' : 'none')};
`;

const Menu = styled.nav<{ open: boolean }>`
  background-color: white;
  position: fixed;
  top: 0;
  right: ${({ open }) => (open ? '0' : '-350px')};
  opacity: ${({ open }) => (open ? '1' : '0')};
  z-index: 300;
  height: 100vh;
  width: 350px;
  transition: all 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  padding: ${dimensions.space.base};

  > * {
    margin-bottom: ${dimensions.space.twentySeven};
  }

  @media screen and (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

const MenuButton = styled.button`
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: ${dimensions.space.base};
  font-size: ${dimensions.text.p}px;
`;

const MobileContainer = styled.div`
  margin-left: auto;
  display: block;

  @media screen and (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

const MediumContainer = styled.nav`
  margin-left: auto;
  display: none;

  @media screen and (min-width: ${breakpoints.lg}) {
    display: flex;
    align-items: center;

    > * {
      margin-left: ${dimensions.space.twentySeven};
    }
  }
`;

const MenuHeader = styled.div`
  margin-bottom: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavItems = () => {
  const { open } = useBrokerModal();
  return (
    <>
      <HeaderItem partiallyActive to="/solutions">
        Vos solutions
      </HeaderItem>
      {/* <JoinUsButton onClick={open}>Rejoignez-nous</JoinUsButton> */}
      <HeaderItem href="https://form.jotform.com/212434660420344" target="_blank">
        Rejoignez-nous
      </HeaderItem>
      <HeaderItem to="/actualites">Quoi de neuf ?</HeaderItem>
      <HeaderItem to="/coin_expert">Le coin des experts</HeaderItem>
      <LoginButtonAndModal />
    </>
  );
};

export default function MainNavigation() {
  const [open, setOpen] = useState(false);

  if (typeof document === 'undefined') {
    return null;
  }

  return (
    <>
      <MediumContainer>
        <NavItems />
      </MediumContainer>
      <MobileContainer>
        <MenuButton onClick={() => setOpen(!open)}>
          <FaBars />
        </MenuButton>
        <Overlay open={open} onClick={() => setOpen(false)} />
        {ReactDOM.createPortal(
          <Menu aria-hidden={!open} open={open}>
            <MenuHeader>
              <Logo />
              <IoCloseSharp
                onClick={() => setOpen(false)}
                style={{
                  cursor: 'pointer',
                  width: '40px',
                  height: '40px',
                  color: colors.black,
                  stroke: colors.white,
                  strokeWidth: 10,
                }}
              />
            </MenuHeader>
            <NavItems />
          </Menu>,
          document.querySelector('body'),
        )}
      </MobileContainer>
    </>
  );
}
