import styled from '@emotion/styled';
import React, { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { colors, fonts, variants } from '../../styles/variables';
import avatar from '../../images/avatar.png';
import JoinUsModal from './JoinUsModal';

const Image = styled.img`
  opacity: 0.6;
  z-index: 1;
  transition: 'opacity .3s ease';
`;

const Wrapper = styled.div`
  cursor: pointer;
  height: 270px;
  width: 230px;
  margin: 20px;

  &:hover ${Image} {
    opacity: 1;
  }

  &:hover .join-us-plus {
    transform: scale(1.3) rotate(90deg);
  }

  &:hover .join-us-square {
    transform: scale(1.1);
  }
`;

const Label = styled.div`
  width: 220px;
  height: 70px;
  background: ${colors.white};
  box-shadow: 0px 0px 5px #62318a57;
  padding: 9px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translate(10px, -10px);
`;

const JoinLooma = styled.div`
  color: ${colors.black};
  font: normal normal bold 19px/25px ${fonts.montserrat};
  letter-spacing: 0px;
  text-transform: uppercase;
`;

const Now = styled.div`
  color: ${colors.black};
  font-weight: ${variants.regular};
  text-transform: uppercase;
  font: normal normal normal 19px/25px ${fonts.montserrat};
  letter-spacing: 0;
`;

const Square = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
  background-color: ${colors.white};
`;

const CrossFading = styled.div`
  position: relative;
  width: 210px;
  height: 210px;
  z-index: 0;
  background-color: ${colors.purple};
`;

const JoinUs: React.FC = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {/* <JoinUsModal show={open} onClose={() => setOpen(false)} /> */}
      {/* <Wrapper onClick={() => setOpen(true)}> */}
      <Wrapper onClick={() => window.open('https://form.jotform.com/212433990062351', '_blank')}>
        <CrossFading>
          {/* TODO: Changer Image et Svg quand Alexandre les aura */}
          <Image src={avatar} alt="Rejoignez nous" />
          <FaPlus
            className="join-us-plus"
            style={{
              position: 'absolute',
              right: '20px',
              top: '20px',
              width: '40px',
              height: '40px',
              color: colors.gold,
              stroke: colors.white,
              strokeWidth: '20',
              transition: 'transform 0.3s ease-in-out',
              zIndex: 2,
            }}
          />
          <Square className="join-us-square" />
        </CrossFading>
        <Label>
          <JoinLooma>Rejoignez looma </JoinLooma>
          <Now>dès maintenant</Now>
        </Label>
      </Wrapper>
    </>
  );
};

export default JoinUs;
