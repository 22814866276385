import React from 'react';
import styled from '@emotion/styled';

import { colors, dimensions } from '../../styles/variables';
import { ProductLink } from '../atoms';

export type ProductProps = {
  id: string;
  strapiId: number;
  name: string;
  url: string;
};

export type ProductCategoryProps = {
  title: string;
  color: string;
  products: ProductProps[];
};

const Container = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  padding: 12px 22px;
`;

const Title = styled.h2`
  font-size: 28px;
  line-height: 35px;
  color: ${colors.white};
  text-transform: uppercase;
  margin: 0;

  ::after {
    background-color: ${colors.white};
    content: '';
    display: block;
    height: 2px;
    width: 33%;
    margin: ${dimensions.space.base} 0;
  }
`;

export default function ProductCategory({ title, color, products }: ProductCategoryProps) {
  return (
    <Container color={color}>
      <Title>{title}</Title>
      {products.map((p) => (
        <ProductLink key={p.id} to={p.url}>
          {p.name}
        </ProductLink>
      ))}
    </Container>
  );
}
