import React from 'react';
import ReactMarkdown, { ReactMarkdownOptions } from 'react-markdown';

export type RenderMarkdownProps = {
  disableParagraph?: boolean;
} & ReactMarkdownOptions;

export default function RenderMarkdown({ disableParagraph, ...props }: RenderMarkdownProps) {
  return <ReactMarkdown components={disableParagraph ? { p: React.Fragment } : undefined} {...props} />;
}
