import { dimensions, fonts, colors, variants, breakpoints } from './variables';

import '@fontsource/montserrat';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/700.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TestimonialImage from '../images/testimonials.jpg';

export default `
  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    font-family: ${fonts.montserrat};
    font-weight: ${variants.regular};
    font-size: ${dimensions.text.p}px !important;
  }

  body {
    width: 100%;
    /*overflow-x: hidden;
    overflow-y: scroll;*/
    font-family: ${fonts.montserrat};
    color: ${colors.black};
    background-color: ${colors.white};
    line-height: ${dimensions.lineHeight.body}px;
    letter-spacing: 0;
    margin: 0;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }

  a {
    color: ${colors.purple};
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  img {
    max-width: 100%;
    object-fit: contain;
    position: relative;
  }

  /*figure {
    margin: 2rem 0;
  }

  figcaption {
    font-size: 80%;
  }*/

  table {
    width: 100%;
    margin-bottom: 1rem;
    border: 1px solid ${colors.black};
    font-size: 85%;
    border-collapse: collapse;
  }

  td,
  th {
    padding: .25rem .5rem;
    border: 1px solid ${colors.black};
  }

  th {
    text-align: left;
  }

  tbody {
    tr {
      &:nth-of-type(odd) {
        td {
          background-color: ${colors.black};
        }
        tr {
          background-color: ${colors.black};
        }
      }
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${fonts.montserrat};
  }

  /*h1 {
    font-size: ${dimensions.text.h1}px;
    font-weight: ${variants.bold};
    line-height: ${dimensions.lineHeight.h1}px;
    margin-block-start: 0.3em;
    margin-block-end: 0.3em;
  }

  h2 {
    font-size: ${dimensions.text.h2}px;
    font-weight: ${variants.medium};
    text-transform: uppercase;
    line-height: ${dimensions.lineHeight.h2}px;
    color: ${colors.purple};
    margin-block-start: 0.4em;
    margin-block-end: 0.4em;
  }*/

  p {
    font-size: ${dimensions.text.p}px;
    font-weight: ${variants.regular};
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
  }

  .fp-section .fp-tableCell {
    display: block;
  }
  
  .full-section .fp-scroller {
    height: 100%; /* FIXME: pourquoi ?????? */
  }

  .section.testimonial-page {
    background-image: linear-gradient(#333E48, transparent 40%), url(${TestimonialImage});
    background-size: cover;
  }

  .section.team-page,
  .section.map-page {
    background-color: ${colors.lightpurple};
    background-position: top right;
    background-size: 75vw 75vw;
    background-repeat: no-repeat;
  }

  .section.team-page {
    background-image: url('data:image/svg+xml,<svg viewbox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><ellipse fill="%23EDE9F0" cx="60%" cy="0%" rx="35%" ry="35%" /></svg>');
  }

  .section.map-page {
    background-image: url('data:image/svg+xml,<svg viewbox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><ellipse fill="%23EDE9F0" cx="52%" cy="10%" rx="50%" ry="50%" /></svg>');
  }

  @media screen and (max-width: ${breakpoints.lg}) {
    .offcanvas-open #fp-nav {
      display: none;
    }
  }


  /* Custom fullpage.js styles */
  #fp-nav.fp-right {
    right: 15px;
  }
  #fp-nav ul li, .fp-slidesNav ul li {
    margin: 14px 0;
    width: 15px;
    height: 15px;
  }
  #fp-nav ul li a span, .fp-slidesNav ul li a span {
    width: 100%;
    height: 100%;
    margin: 0;
    box-shadow: 0px 0px 6px #00000029;
    background: ${colors.white};
    border: 2px solid ${colors.white};
    transform: translate(-50%, -50%);
  }
  #fp-nav ul li:hover a.active span, #fp-nav ul li a.active span, .fp-slidesNav ul li:hover a.active span, .fp-slidesNav ul li a.active span {
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 6px #00000029;
    margin: 0;
    background: ${colors.gold};
    border: 2px solid ${colors.white};
  }
  #fp-nav ul li:hover a span, .fp-slidesNav ul li:hover a span {
    width: 100%;
    height: 100%;
    margin: 0;
  }
`;
