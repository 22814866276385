import React from 'react';
import styled from '@emotion/styled';

type Props = {
  embedId: string;
};

const YoutubeVideoWrapper = styled.div`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
`;

const YoutubeVideo = styled.iframe`
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
`;

const YoutubeEmbed: React.FC<Props> = ({ embedId }) => {
  return (
    <YoutubeVideoWrapper>
      <YoutubeVideo
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </YoutubeVideoWrapper>
  );
};

export default YoutubeEmbed;
