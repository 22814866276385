import styled from '@emotion/styled';
import Img, { FluidObject } from 'gatsby-image';
import React from 'react';

import { colors } from '../../styles/variables';

const Card = styled.div`
  background-color: ${colors.white};
  border-radius: 5px;
  border: 1px solid ${colors.lila};
  padding: 16px 26px;
  position: relative;
  box-shadow: 0px 0px 10px #62318a24;
  width: 260px;
  transition: all 0.3s ease-in-out;

  :hover,
  :focus {
    transform: translateY(-10%);
  }
`;

const Title = styled.h3`
  font-size: 17px;
  line-height: 20px;
  font-weight: bold;
  margin-top: 84px;
`;

const IconWrapper = styled.div`
  background-color: ${colors.lila};
  border-radius: 100%;
  border: 7px solid ${colors.white};
  height: 116px;
  width: 116px;
  padding: 30px;
  position: absolute;
  top: -24px;
  left: 12.5%;

  > * {
    height: 100%;
    width: 100%;
  }
`;

const Value = styled.span`
  background-color: ${colors.purple};
  border-radius: 100%;
  color: ${colors.white};
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: absolute;
  top: 5px;
  left: 47.5%;
  padding: 16px;
  line-height: 20px;
  height: 82px;
  width: 82px;
`;

export type StatisticProps = {
  id: string;
  title: string;
  icon: {
    localFile: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
  };
  value: string;
};

export default function Statistic({ title, icon, value }: StatisticProps) {
  return (
    <Card>
      <IconWrapper>
        <Img
          imgStyle={{ objectFit: 'contain' }}
          fluid={icon.localFile.childImageSharp.fluid}
          alt={`${value} ${title}`}
        />
      </IconWrapper>
      <Title>
        {/* Je le place ici pour le SEO */}
        <Value>{value} </Value>
        {title}
      </Title>
    </Card>
  );
}
