import React from 'react';
import styled from '@emotion/styled';
import Masonry from 'react-masonry-component';

import { PageWrapper } from '../atoms';
import { Carousel, SectionHeader, Testimonial, TestimonialProps } from '../molecules';

import { dimensions } from '../../styles/variables';
import { useWindowDimensions } from '../../hooks';

export type TestimonialsProps = {
  title: string;
  pretitle: string;
  testimonials: TestimonialProps[];
};

const cardWidth = 440;

const CarouselWrapper = styled.div`
  padding-top: ${dimensions.space.fourtyEight};
  padding-bottom: ${dimensions.space.fourtyEight};
  margin: 0 ${dimensions.space.twenty};
`;

const TestimonialContainer = styled(Masonry)`
  max-width: calc(2 * ${cardWidth}px);
  display: flex;
  margin: 0 auto;
`;

export default function Testimonials({ title, pretitle, testimonials }: TestimonialsProps) {
  const { windowWidth } = useWindowDimensions();
  const chunkArray = (arr, size) =>
    arr.length > size ? [arr.slice(0, size), ...chunkArray(arr.slice(size), size)] : [arr];

  return (
    <PageWrapper>
      <SectionHeader title={title} pretitle={pretitle} variant="light" />
      <CarouselWrapper>
        <Carousel>
          {chunkArray(testimonials, windowWidth >= 992 ? 4 : 1).map((c) => {
            return (
              <TestimonialContainer key={c}>
                {c.map((t) => (
                  <Testimonial key={t.firstname} {...t} cardWidth={cardWidth} />
                ))}
              </TestimonialContainer>
            );
          })}
        </Carousel>
      </CarouselWrapper>
    </PageWrapper>
  );
}
