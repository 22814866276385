import styled from '@emotion/styled';
import React from 'react';
import Img from 'gatsby-image';

import { colors, dimensions } from '../../styles/variables';
import type { Region } from './InteractiveMap';
import type { Image } from './Member';

export type MapContactProps = {
  id: string;
  image: Image;
  fullname: string;
  contact: string;
  region: Region;
};

const Container = styled.div`
  background-color: ${colors.white};
  border-radius: 5px;
  display: flex;
  box-shadow: 0px 3px 6px #00000029;
  min-height: 125px;

  & + & {
    margin-top: ${dimensions.space.base};
  }
`;

const ProfileImg = styled(Img)`
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const InfoWrapper = styled.div`
  flex: 2;
  padding: 16px;
  word-wrap: anywhere;
`;

const Fullname = styled.p`
  font-size: 16px;
  font-weight: bold;
  line-height: 23px;
`;

export default function MapContact({ fullname, contact, image }: MapContactProps) {
  return (
    <Container tabIndex={0}>
      <div style={{ position: 'relative', flex: 1, minWidth: '33%' }}>
        <ProfileImg
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          imgStyle={{ objectPosition: 'center top' }}
          fixed={image.localFile.childImageSharp.fixed}
        />
      </div>
      <InfoWrapper>
        <Fullname>{fullname}</Fullname>
        <a href={`mailto:${contact}`} title={`Envoyer un mail à ${fullname}`}>
          {contact}
        </a>
      </InfoWrapper>
    </Container>
  );
}
