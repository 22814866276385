import styled from '@emotion/styled';
import React, { useState } from 'react';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import { css } from '@emotion/react';

import { breakpoints, colors, dimensions, variants } from '../../styles/variables';
import { Modal, RenderMarkdown } from '../atoms';

export type TestimonialProps = {
  title: string;
  extract: string;
  content: string;
  function: string;
  workPlace: string;
  firstname: string;
  lastname: string;
  cardWidth?: number;
};

const Wrapper = styled.div<{ cardWidth: number }>`
  cursor: pointer;
  background-color: white;
  border-radius: 5px;
  padding: ${dimensions.space.base};
  margin: 10px;

  @media screen and (min-width: ${breakpoints.lg}) {
    // cardWidth : Taille de la carte par défaut
    // Si un moment la carte se réduit à moins de cardWidth px alors la carte prendra 50% de la largeur
    // (10px = margin)
    max-width: min(${({ cardWidth }) => cardWidth}px, calc(50% - 2 * 10px));
  }
`;

const Title = styled.span`
  font-size: 2rem;
  font-weight: lighter;
`;

const Content = styled.div`
  margin-top: ${dimensions.space.base};
  margin-bottom: 24px;

  ul {
    padding-left: 1rem;
  }
`;

const Author = styled.figcaption`
  text-align: right;
  position: relative;
  padding-top: 8px;
  font-size: 0.8rem;
`;

const PinkBorder = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 1px;
  background-color: ${colors.lila};
  width: 160px;
  margin-right: -${dimensions.space.base};
`;

const Testimonial: React.FC<TestimonialProps> = ({
  title,
  extract,
  content,
  function: role,
  workPlace,
  firstname,
  lastname,
  cardWidth,
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Wrapper
        cardWidth={cardWidth}
        role="button"
        tabIndex={0}
        onClick={() => setShowModal(true)}
        onKeyDown={(e) => (e.key === 'Enter' ? setShowModal(true) : {})}
      >
        <figure style={{ margin: 0 }}>
          <blockquote style={{ margin: 0 }}>
            <FaQuoteLeft
              css={css`
                font-size: 30px;
                margin-right: 10px;
                vertical-align: sub; // OR text-bottom
                color: ${colors.purple};
              `}
            />
            <Title>{title}</Title>
            <Content>
              <RenderMarkdown>{extract || content}</RenderMarkdown>
              &nbsp;
              <FaQuoteRight
                css={css`
                  font-size: 20px;
                  margin-left: 10px;
                  transform: translateY(10px);
                  color: ${colors.lila};
                `}
              />
            </Content>
          </blockquote>
          <Author>
            <PinkBorder />
            <cite style={{ fontStyle: 'normal' }}>
              {firstname} {lastname}
            </cite>{' '}
            - {role} de <strong>{workPlace}</strong>
          </Author>
        </figure>
      </Wrapper>
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        title={title}
        footer={
          <div>
            <Author>
              <PinkBorder style={{ marginRight: '-40px' }} />
              <cite style={{ fontStyle: 'normal' }}>
                {firstname} {lastname}
              </cite>{' '}
              - {role} de <strong>{workPlace}</strong>
            </Author>
          </div>
        }
      >
        <RenderMarkdown>{content}</RenderMarkdown>
      </Modal>
    </>
  );
};

export default Testimonial;
