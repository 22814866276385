import { css, Global } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { breakpoints, colors, dimensions } from '../../styles/variables';
import { PageWrapper, Logo } from '../atoms';
import MainNavigation from './MainNavigation';

const Wrapper = styled.header`
  padding-top: ${dimensions.space.ten};
  padding-bottom: ${dimensions.space.ten};
  min-height: 72px;
  position: fixed;
  top: 0;
  left: 0;
  background: ${colors.white};
  width: 100%;
  z-index: 300;
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  @media screen and (min-width: ${breakpoints.lg}) {
    position: initial;
    left: initial;
    transform: initial;
  }
`;

const Header = () => {
  return (
    <>
      {/* Custom : Add box-shadow but not on the home page (video) and DNA page */}
      <Global
        styles={css`
          body:not(.fp-viewing-0):not(.fp-viewing-1) ${Wrapper} {
            box-shadow: 0px 0px 10px #55555563;
          }
          @media screen and (min-width: ${breakpoints.md}) {
            ${Wrapper} {
              box-shadow: 0px 0px 10px #55555563;
            }
          }
        `}
      />
      <Wrapper>
        <PageWrapper>
          <InnerWrapper>
            <LogoContainer>
              <Logo />
            </LogoContainer>
            <MainNavigation />
          </InnerWrapper>
        </PageWrapper>
      </Wrapper>
    </>
  );
};

export default Header;
