/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PropsWithChildren, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { Transition } from 'react-transition-group';
import { IoCloseSharp } from 'react-icons/io5';
import styled from '@emotion/styled';
import Img, { FixedObject } from 'gatsby-image';
import { PreTitle, RenderMarkdown } from '../atoms';
import { breakpoints, colors, dimensions } from '../../styles/variables';

type Image = {
  localFile: {
    childImageSharp: {
      fixed: FixedObject;
    };
  };
};

export type DnaItemProps = {
  pretitle: string;
  name: string;
  description: string;
  image: Image;
};

const animationDuration = 1000;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  max-width: 700px;
  transition: all ${animationDuration}ms ease-in-out;
  color: ${colors.white};
  position: relative;
`;

const Overlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${colors.purple};
  border-radius: 50%;
  transition: all ${animationDuration}ms ease-in-out;
`;

const CircleImageWrapper = styled.div`
  @media screen and (max-width: ${breakpoints.md}) {
    display: none;
  }
`;

const CircleImage = styled.div`
  height: 40vw;
  width: 40vw;
  border-radius: 50%;
  overflow: hidden;
  transition: all ${animationDuration}ms ease-in-out;

  img {
    object-fit: cover;
    height: 100%;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
  background-color: ${colors.white};
  display: flex;
  border-radius: 10px 0 0 10px;
`;

const ValueTitle = styled.div`
  font-size: 1.75rem;
  line-height: 61px;
  margin-bottom: 48px;
  transition: all ${animationDuration}ms ease-in-out;
  @media screen and (min-width: ${breakpoints.md}) {
    font-size: 50px;
  }
`;

const ValueContent = styled.div`
  margin-top: 20px;
  flex-grow: 1;
  overflow: auto;
  transition: all ${animationDuration}ms ease-in-out;
`;

const ModalContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  // flex-direction: column;
  // justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const ModalWrapper = styled.div<{ active: boolean }>`
  position: fixed;
  z-index: 101;
  padding-top: 72px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  transition: all ${animationDuration}ms ease-in-out;
  opacity: ${({ active }) => (active ? 1 : 0)};
  pointer-events: ${({ active }) => (active ? 'visible' : 'none')};

  ${Overlay} {
    width: ${({ active }) => (active ? 'max(150vw, 150vh)' : 0)};
    height: ${({ active }) => (active ? 'max(150vw, 150vh)' : 0)};
  }

  ${CircleImage} {
    ${({ active }) =>
      active
        ? `
          transform: scale(1);
        `
        : `
          transform: scale(0);
        `}
  }

  ${ValueTitle}, ${ValueContent} {
    ${({ active }) =>
      active
        ? `
          transform: translateY(0);
        `
        : `
          transform: translateY(100vh);
        `}
  }
`;

export default function DnaAtom({ pretitle, name, description, image, children }: PropsWithChildren<DnaItemProps>) {
  const [show, setShow] = useState(false);
  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      setShow(false);
    }
  };

  const onInteraction = (evt) => {
    let value: any;

    // Input clavier type "Entrée"
    if ((evt.charCode || evt.keyCode) === 13) {
      value = evt.target.id;
    } else if (!evt.keyCode) {
      // Click / touch event
      value = evt.currentTarget.id;
    }

    if (value) {
      setShow(true);
    }
  };

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  });

  if (typeof document === 'undefined') {
    return null;
  }

  return (
    <>
      <a
        id={name}
        role="button"
        tabIndex={0}
        onClick={onInteraction}
        onKeyDown={onInteraction}
        style={{ color: 'inherit' }}
      >
        {children}
      </a>
      {ReactDOM.createPortal(
        <Transition in={show} timeout={{ enter: 0, exit: animationDuration }} unmountOnExit>
          {(state) => (
            <ModalWrapper active={state === 'entered'} className="fullpage-no-scroll">
              <ModalContainer>
                <Overlay />
                <CircleImageWrapper>
                  <CircleImage>
                    <Img fixed={image.localFile.childImageSharp.fixed} style={{ width: '100%', height: '100%' }} />
                  </CircleImage>
                </CircleImageWrapper>
                <Content>
                  <PreTitle variant="light" style={{ paddingTop: dimensions.space.fourtyEight }}>
                    {pretitle}
                  </PreTitle>
                  <ValueTitle>{name}</ValueTitle>
                  <ValueContent>
                    <RenderMarkdown>{description}</RenderMarkdown>
                  </ValueContent>
                </Content>
                <CloseButton tabIndex={0} role="button">
                  <IoCloseSharp
                    onClick={() => setShow(false)}
                    style={{
                      width: '55px',
                      height: '55px',
                      color: colors.gold,
                    }}
                  />
                </CloseButton>
              </ModalContainer>
            </ModalWrapper>
          )}
        </Transition>,
        document.querySelector('body'),
      )}
    </>
  );
}
