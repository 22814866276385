import styled from '@emotion/styled';
import React from 'react';
import Tippy, { TippyProps } from '@tippyjs/react/headless';

import { colors } from '../../styles/variables';

type Props = TippyProps & {};

const tooltipAngle = 0.09;

const TooltipWrapper = styled.div`
  background-color: ${colors.purple};
  color: ${colors.white};
  box-shadow: -2px 2px 0px #ede9f0;
  border-radius: 4px;
  transform: matrix(1, -${tooltipAngle}, ${tooltipAngle}, 1, 0, 0);
  font-size: 12px;
  line-height: 21px;
  padding: 2px 10px;
  font-weight: 500;
`;

const TooltipText = styled.div`
  transform: matrix(1, ${tooltipAngle / 2}, -${tooltipAngle / 2}, 1, 0, 0);
`;

const Tooltip: React.FC<Props> = ({ children, content, ...props }) => {
  return (
    <Tippy
      {...props}
      render={(attrs) => (
        <TooltipWrapper className="box" role="button" tabIndex={-1} {...attrs}>
          <TooltipText>{content}</TooltipText>
        </TooltipWrapper>
      )}
    >
      <div role="button" tabIndex={0} style={{ display: 'flex' }}>
        {children}
      </div>
    </Tippy>
  );
};
export default Tooltip;
