import styled from '@emotion/styled';
import React, { createContext, PropsWithChildren, useCallback, useContext, useRef, useState } from 'react';
import { Button, Modal } from '../atoms';
import logo from '../../images/logo.png';
import { widths } from '../../styles/variables';

type BrokerModalValue = {
  isOpened: boolean;
  open();
  close();
};

const BrokerModalContext = createContext<BrokerModalValue | undefined>(undefined);

export function useBrokerModal() {
  const value = useContext(BrokerModalContext);

  if (!value) {
    throw new Error();
  }

  return value;
}

export function BrokerProvider({ children }: PropsWithChildren<{}>) {
  const [isOpened, setIsOpened] = useState(false);

  const open = useCallback(() => setIsOpened(true), []);
  const close = useCallback(() => setIsOpened(false), []);

  return (
    <BrokerModalContext.Provider
      value={{
        open,
        close,
        isOpened,
      }}
    >
      {children}
    </BrokerModalContext.Provider>
  );
}

const Description = styled.p`
  margin-bottom: 24px;
`;

const Textinput = styled.input`
  font: inherit;
  display: block;
  width: 100%;
  padding: 12px 18px;
  border: 2px solid #b8b8b8;
  border-radius: 5px;
  margin-top: 12px;
`;

const SubmitButton = styled(Button)`
  margin-top: 21px;
  margin-left: auto;
`;

const Header = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-size: 33px;
  line-height: 40px;
  font-weight: normal;
`;

// TODO: à refactoriser avec JoinUsModal ...

export default function BrokerModal() {
  const { isOpened, close } = useBrokerModal();
  const firstName = useRef<HTMLInputElement>();
  const lastName = useRef<HTMLInputElement>();
  const email = useRef<HTMLInputElement>();
  const phone = useRef<HTMLInputElement>();

  return (
    <Modal show={isOpened} onClose={close}>
      <Header>
        Rejoignez
        <img alt="logo" src={logo} width={widths.sm} />
      </Header>
      <Description>Intéressé par un partenariat ? C’est simple comme une discussion…commençons ici !</Description>
      <form
        onSubmit={(e) => {
          window.open(
            `mailto:nullepart@looma.fr?subject=${encodeURIComponent(
              'Prise de contact',
            )}&body=${encodeURIComponent(`Démarrons l'échange ! Mes coordonnées :

          Prénom: ${firstName.current.value}
          Nom: ${lastName.current.value}
          Email: ${email.current.value}
          Numéro de téléphone: ${phone.current.value}
          `)}`,
          );
          e.preventDefault();
        }}
      >
        <Textinput type="text" required placeholder="Votre nom" ref={firstName} />
        <Textinput type="text" required placeholder="Votre prénom" ref={lastName} />
        <Textinput type="email" required placeholder="Votre email" ref={email} />
        <Textinput type="text" required placeholder="Numéro de téléphone" ref={phone} />
        <SubmitButton type="submit">Démarrer la discussion</SubmitButton>
      </form>
    </Modal>
  );
}
