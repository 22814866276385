import React, { PropsWithChildren, useRef } from 'react';
import Slider, { Settings, CustomArrowProps } from 'react-slick';
import { FiPlay } from 'react-icons/fi';
import styled from '@emotion/styled';
import { breakpoints, colors, dimensions } from '../../styles/variables';

type Props = {
  noArrows?: boolean;
};

const CustomSlider = styled(Slider)<{ noArrows?: boolean }>`
  margin-bottom: 65px;

  .slick-slide > div {
    display: flex !important;
  }

  .slick-track {
    display: flex;
    align-items: center;
  }

  .slick-list {
    margin: 0 !important;

    ${({ noArrows }) =>
      !noArrows &&
      `
      @media screen and (min-width: ${breakpoints.sm}) {
        margin: 0 45px !important;
      }

      @media screen and (min-width: ${breakpoints.lg}) {
        margin: 0 70px !important;
      }
    `}
  }

  .slick-dots li {
    width: 22px !important;
    height: 22px !important;
  }
`;

const Arrow = styled.button`
  font-size: inherit !important;
  line-height: inherit !important;
  color: inherit !important;
  transform: translateY(-50%) !important;
  width: 40px !important;
  height: 40px !important;
  background-color: ${colors.purple} !important;
  border-radius: 50% !important;
  transition: all 0.3s ease-in-out !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 10 !important;
  display: none !important;

  &::before {
    content: '' !important;
  }

  &:focus {
    color: inherit !important;
    background-color: ${colors.purple} !important;
  }

  &:hover {
    color: inherit;
    background-color: rgba(98, 49, 138, 0.5) !important;
  }

  @media screen and (min-width: ${breakpoints.sm}) {
    display: flex !important;
  }

  @media screen and (min-width: ${breakpoints.lg}) {
    width: 65px !important;
    height: 65px !important;
  }
`;

const PlayIcon = styled(FiPlay)`
  stroke: ${colors.white} !important;
  font-size: ${dimensions.space.twentySeven} !important;

  @media screen and (min-width: ${breakpoints.lg}) {
    font-size: ${dimensions.space.fourtyOne} !important;
  }
`;

const PrevArrow = styled(Arrow)`
  transform: translateY(-50%) scale(-1, 1) !important;
  left: 0 !important;
`;

const NextArrow = styled(Arrow)`
  right: 0 !important;
`;

const DotPlayIcon = styled(FiPlay)`
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
`;

const DotArrow = styled.div`
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  border-radius: 50% !important;
  background-color: ${colors.purple} !important;
  color: ${colors.white} !important;
  padding: 0 !important;
  transition: all 0.3s ease-in-out !important;

  &:hover {
    background-color: rgba(98, 49, 138, 0.5) !important;
  }

  &::before {
    content: none !important;
  }

  font-size: initial !important;
  line-height: normal !important;
  display: inherit !important;
`;

const DotPrevArrow = styled(DotArrow)`
  transform: scale(-1, 1) !important;
`;

const DotNextArrow = styled(DotArrow)``;

const Dot = styled.div`
  width: 100% !important;
  height: 100% !important;
  background-color: ${colors.white} !important;
  border: 2px solid ${colors.purple} !important;
  border-radius: 50% !important;
  position: relative !important;

  &::before {
    content: '' !important;
    border-radius: 50% !important;
    position: absolute !important;
    width: 12px !important;
    height: 12px !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
`;

const DotContainer = styled.ul`
  bottom: -65px !important;

  > li:not(.dot-navigation):not(.slick-active) ${Dot}:hover::before {
    background-color: ${colors.lila} !important;
    opacity: 0.5 !important;
  }

  .slick-active ${Dot}::before {
    background-color: ${colors.lila} !important;
  }
`;

const PrevArrowButton: React.FC<CustomArrowProps> = ({ ...props }) => {
  return (
    <PrevArrow {...props} type="button">
      <PlayIcon />
    </PrevArrow>
  );
};

const NextArrowButton: React.FC<CustomArrowProps> = ({ ...props }) => {
  return (
    <NextArrow {...props} type="button">
      <PlayIcon />
    </NextArrow>
  );
};

const DotPrevArrowButton: React.FC<any> = ({ onClick, ...props }) => {
  return (
    <DotPrevArrow
      {...props}
      tabIndex={0}
      role="button"
      onClick={onClick}
      onKeyDown={(e) => e.key === 'Enter' && onClick()}
    >
      <DotPlayIcon />
    </DotPrevArrow>
  );
};

const DotNextArrowButton: React.FC<any> = ({ onClick, ...props }) => {
  return (
    <DotNextArrow
      {...props}
      tabIndex={0}
      role="button"
      onClick={onClick}
      onKeyDown={(e) => e.key === 'Enter' && onClick()}
    >
      <DotPlayIcon />
    </DotNextArrow>
  );
};

const Carousel: React.FC<PropsWithChildren<Props>> = ({ noArrows, children }) => {
  const ref = useRef<Slider>();
  const settings: Settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: noArrows ? <></> : <PrevArrowButton />,
    nextArrow: noArrows ? <></> : <NextArrowButton />,
    appendDots: (dots) =>
      (
        <DotContainer>
          <li className="dot-navigation">
            <DotPrevArrowButton onClick={() => ref && ref.current && ref.current.slickPrev()} />
          </li>
          {dots}
          <li className="dot-navigation">
            <DotNextArrowButton onClick={() => ref && ref.current && ref.current.slickNext()} />
          </li>
        </DotContainer>
      ) as JSX.Element,
    customPaging: () => (<Dot />) as JSX.Element,
    dotsClass: 'slick-dots',
  };

  return (
    <CustomSlider {...settings} ref={ref} noArrows={noArrows}>
      {children}
    </CustomSlider>
  );
};

export default Carousel;
