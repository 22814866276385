import React, { AnchorHTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import { colors } from '../../styles/variables';

const Styled = styled.base`
  color: ${colors.black};
  position: relative;
  text-align: center;

  ::after {
    content: '';
    background-color: ${colors.gold};
    display: block;
    height: 2px;
    width: 0;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    transition: width 0.3s ease-in-out;
  }

  :hover,
  :focus {
    text-decoration: none;

    ::after {
      width: 25%;
    }
  }
`;

type LinkProps = { to: string; partiallyActive?: boolean };
type AnchorProps = { href: string; target?: string };

type Props = AnchorHTMLAttributes<HTMLAnchorElement> & (LinkProps | AnchorProps);

function isAnchor(props: Props): props is AnchorProps {
  return !!props.href;
}

export default function HeaderItem(props: Props) {
  if (isAnchor(props)) {
    const Anchor = Styled.withComponent('a');

    return <Anchor {...props} />;
  }

  const GatsbyLink = Styled.withComponent(Link);

  // eslint-disable-next-line react/destructuring-assignment
  return <GatsbyLink activeStyle={{ fontWeight: 'bold' }} {...props} />;
}
