/* eslint-disable max-len */

export type Theme = 'light' | 'dark';

export const colors = {
  purple: '#62318A',
  lila: '#C2B1D3',
  lightLila: '#EDE9F0',
  darkLila: '#815AA1',
  lightpurple: '#F3EFF6',
  white: '#fff',
  black: '#333E48',
  gold: '#FCA01F',
  pink: '#FC1F95',
};

export const fonts = {
  montserrat: "'Montserrat', sans-serif",
};

export const variants = {
  regular: 400,
  medium: 500,
  bold: 700,
};

export const breakpoints = {
  xs: '0px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};

export const widths = {
  sm: 161,
  md: 720,
  lg: 960,
  xl: 1140,
};

export const dimensions = {
  text: {
    h1: 41,
    h2: 13,
    p: 17,
  },
  lineHeight: {
    h1: 50,
    h2: 16,
    body: 26,
  },
  modal: {
    md: '530px',
    lg: '720px',
  },
  space: {
    ten: '10px',
    twelve: '12px',
    base: '16px',
    twenty: '20px',
    twentySeven: '27px',
    fourtyEight: '48px',
    fourtyOne: '41px',

    header: 153,
    menuItems: 27,
    slideTitle: 60,
    button: {
      signin: 20,
      small: {
        x: 10,
        y: 8,
      },
      medium: {
        x: 11,
        y: 9,
      },
      large: {
        x: 17,
        y: 12,
      },
    },
  },
};

export const heights = {
  header: 57,
  footer: 400,
  button: {
    small: 35,
    medium: 37,
    large: 45,
  },
};
