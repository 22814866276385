import styled from '@emotion/styled';
import React, { PropsWithChildren, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Transition } from 'react-transition-group';
import { IoCloseSharp } from 'react-icons/io5';

import Img, { FluidObject } from 'gatsby-image';
import { breakpoints, colors, dimensions } from '../../styles/variables';

type Props = {
  show: boolean;
  onClose: any;
  title?: string;
  size?: 'md' | 'lg';
  footer?: JSX.Element;
  headerImage?: {
    localFile: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
  };
};

const animationDuration = 300;

const Content = styled.div<{ size: string }>`
  width: ${({ size }) => dimensions.modal[size]};
  max-width: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: all ${animationDuration}ms ease-in-out;
`;

const Wrapper = styled.div<{ active: boolean }>`
  z-index: 400;
  position: fixed;
  padding: 20px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(38, 19, 54, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ${animationDuration}ms ease-in-out;
  opacity: ${({ active }) => (active ? 1 : 0)};
  pointer-events: ${({ active }) => (active ? 'visible' : 'none')};

  & ${Content} {
    transform: translateY(${({ active }) => (active ? 0 : '50vh')});
  }
`;

const Header = styled.div``;

const HeaderImage = styled(Img)`
  max-height: 300px;
  object-fit: cover;
  width: 100%;
`;

const Title = styled.div`
  margin: 0;
  padding: 20px 50px 10px;
  position: relative;
  font-size: 25px;
  font-weight: bold;
  line-height: 30px;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 150px;
    height: 3px;
    background-color: ${colors.lila};
  }
`;

const Body = styled.div`
  flex-grow: 1;
  overflow: auto;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  @media screen and (min-width: ${breakpoints.md}) {
    padding: 20px 50px;
  }
`;

const Footer = styled.div`
  padding: 10px 40px 30px;
`;

const Modal: React.FC<PropsWithChildren<Props>> = ({
  show,
  onClose,
  title,
  children,
  footer,
  headerImage,
  size = 'lg',
}) => {
  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  });

  if (typeof document === 'undefined') {
    return null;
  }

  return ReactDOM.createPortal(
    <Transition in={show} timeout={{ enter: 0, exit: animationDuration }} unmountOnExit>
      {(state) => (
        <Wrapper onClick={onClose} active={state === 'entered'} className="fullpage-no-scroll">
          <Content onClick={(e) => e.stopPropagation()} size={size}>
            <Header>
              {headerImage && <HeaderImage fluid={headerImage.localFile.childImageSharp.fluid} />}
              {title && <Title>{title}</Title>}
              <IoCloseSharp
                onClick={onClose}
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  right: '0',
                  top: '0',
                  width: '40px',
                  height: '40px',
                  color: colors.gold,
                  zIndex: 2,
                  stroke: colors.white,
                  strokeWidth: 10,
                }}
              />
            </Header>
            <Body>{children}</Body>
            {footer && <Footer>{footer}</Footer>}
          </Content>
        </Wrapper>
      )}
    </Transition>,
    document.querySelector('body'),
  );
};
export default Modal;
