import styled from '@emotion/styled';
import React from 'react';
import { IoChatbubblesOutline } from 'react-icons/io5';

import { Button, LinkButton, PageWrapper, PreTitle, RenderMarkdown, Title, Video } from '../atoms';

import homeVideo from '../../videos/iStock-1132482720.mp4';
import { useBrokerModal } from '../molecules/BrokerModal';
import { breakpoints, dimensions } from '../../styles/variables';

export type HomeProps = {
  pretitle: string;
  title: string;
  description: string;
  video: {
    url: string;
    mime: string;
  };
};

const Circle = styled.div`
  position: absolute;
  top: 2%;
  left: 10%;
  background-color: rgba(255, 255, 255, 0.5);
  width: 1000px;
  height: 1000px;
  border-radius: 50%;
  display: none;
  @media screen and (min-width: ${breakpoints.md}) {
    display: block;
  }
`;

const BackgroundCircle = styled(Circle)`
  transform: translate(-50%, -50%) scale(1.03);
  transition: all 0.3s ease-in-out;
  z-index: -1;
`;

const ForegroundCircle = styled(Circle)`
  transform: translate(-50%, -50%);
  z-index: 0;
`;

const SectionWrapper = styled(PageWrapper)`
  height: 100%;
  display: flex;
  @media screen and (min-width: ${breakpoints.md}) {
    align-items: center;
  }
`;

const Section = styled.section`
  position: relative;
  margin-bottom: 40px;
  @media screen and (min-width: ${breakpoints.md}) {
    max-width: 400px;
    margin-bottom: 72px;
  }

  &:hover ${BackgroundCircle} {
    transform: translate(-50%, -50%) scale(1.05);
  }
`;

const Titles = styled.div`
  background-color: white;
  // width: 100%;
  position: relative;
  text-align: center;
  margin: 0 -16px;
  padding: 40px 20px 0;

  @media screen and (min-width: ${breakpoints.md}) {
    background-color: initial;
    text-align: initial;
    width: auto;
    padding: 0 0 0;
    margin: 0;
  }

  // Other Method pour svg circular
  // &::before {
  //   content: '';
  //   position: absolute;
  //   bottom: -50px;
  //   left: 0;
  //   width: 100%;
  //   height: 50px;
  //   background-color: white;
  //   clip-path: ellipse(55% 125% at 50% -25%);
  // }
`;

const Description = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  @media screen and (min-width: ${breakpoints.md}) {
    background-color: initial;
  }
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 14px;

  justify-content: center;
  @media screen and (min-width: ${breakpoints.md}) {
    justify-content: initial;
  }
`;

const SectionContent = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  text-align: center;
  @media screen and (min-width: ${breakpoints.md}) {
    text-align: initial;
  }
`;

const MobileContainer = styled.div`
  position: relative;
  display: block;
  flex-grow: 1;
  @media screen and (min-width: ${breakpoints.md}) {
    display: none;
  }
`;

const VideoContainer = styled.div`
  position: relative;
  flex-grow: 1;
  @media screen and (min-width: ${breakpoints.md}) {
    position: static;
  }
`;

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const CircleContainer = styled.div`
  position: relative;
`;

export default function Home({ title, pretitle, description, video }: HomeProps) {
  const { open } = useBrokerModal();
  return (
    <SectionWrapper>
      <Section>
        <SectionContent>
          <CircleContainer>
            <ForegroundCircle />
            <BackgroundCircle />
          </CircleContainer>
          <ContentContainer>
            <Titles>
              <PreTitle variant="dark">{pretitle}</PreTitle>
              <Title variant="dark">{title}</Title>
            </Titles>
            <VideoContainer>
              <MobileContainer>
                <svg style={{ width: '100vw', height: '50px', margin: `0 -${dimensions.space.base}` }}>
                  <ellipse fill="white" rx="55%" ry="125%" cx="50%" cy="-25%" />
                </svg>
              </MobileContainer>
              <div>
                <Video {...video} absoluteUrl={homeVideo} />
              </div>
            </VideoContainer>
            <Description>
              <RenderMarkdown>{description}</RenderMarkdown>
              <Buttons>
                <Button to="/solutions">Foncez !</Button>
                {/* <Button transparent onClick={open} leftIcon={<IoChatbubblesOutline />}> */}
                <Button
                  transparent
                  href="https://form.jotform.com/212434660420344"
                  target="_blank"
                  leftIcon={<IoChatbubblesOutline />}
                >
                  Echangeons !
                </Button>
              </Buttons>
            </Description>
          </ContentContainer>
        </SectionContent>
      </Section>
    </SectionWrapper>
  );
}
