import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import logo from '../../images/logo.png';
import { widths } from '../../styles/variables';

const Anchor = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none !important;
`;

export default function Logo() {
  return (
    <Anchor to="/" title="Accueil">
      <img alt="logo" src={logo} width={widths.sm} />
    </Anchor>
  );
}
