import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React from 'react';
import { colors, dimensions, fonts, heights, variants } from '../../styles/variables';

type AnchorProps = { href: string; target?: string };
type LinkProps = {
  to: string;
};
type ButtonProps = { onClick: React.MouseEventHandler<HTMLButtonElement> };

type Props = React.HTMLAttributes<HTMLDivElement> & {
  type?: 'submit' | 'button';
  color?: 'gold' | 'purple' | 'white';
  hovercolor?: 'gold' | 'purple' | 'white';
  size?: 'small' | 'medium' | 'large';
  variant?: 'rectangular' | 'rounded';
  transparent?: boolean;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
} & (LinkProps | AnchorProps | ButtonProps);

function isAnchor(props: any): props is AnchorProps {
  return !!props.href;
}

function isLink(props: any): props is LinkProps {
  return !!props.to;
}

const Styled = styled.base<Props>`
  font-family: ${fonts.montserrat};
  font-weight: ${variants.regular};
  font-size: ${dimensions.text.p}px;
  background: ${({ transparent, color }) => (transparent ? 'transparent' : colors[color])};
  color: ${({ transparent, color }) => (transparent ? colors[color] : colors.white)};
  border: ${({ transparent, color }) => (transparent ? `1px solid ${colors[color]}` : 'none')};
  border-radius: ${({ variant }) => (variant === 'rounded' ? '17px' : '5px')};
  height: ${({ size }) => heights.button[size]}px;
  line-height: 26px;
  letter-spacing: 0;
  cursor: pointer;
  padding: ${({ size }) => `0px ${dimensions.space.button[size].x}px`};
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  text-decoration: none !important;

  ${({ hovercolor }) => `
  &:hover {
    background-color: ${colors[hovercolor]};
  }
  `}
`;

const WrapperIcon = styled.span<{ position: string }>`
  display: flex;
  margin-${(props) => (props.position === 'left' ? 'right' : 'left')}: 7px;
`;

const Button: React.FC<Props> = ({
  children,
  type,
  onTouchCancel,
  color = 'purple',
  hovercolor,
  size = 'large',
  variant = 'rounded',
  leftIcon,
  rightIcon,
  style,
  className,
  transparent = false,
  ...props
}) => {
  let StyledButton;

  if (isLink(props)) {
    StyledButton = Styled.withComponent(Link);
  } else if (isAnchor(props)) {
    StyledButton = Styled.withComponent('a');
  } else {
    StyledButton = Styled.withComponent('button');
  }

  return (
    <StyledButton
      type={type}
      color={color}
      hovercolor={hovercolor}
      size={size}
      variant={variant}
      transparent={transparent}
      style={style}
      className={className}
      {...props}
    >
      {leftIcon && <WrapperIcon position="left">{leftIcon}</WrapperIcon>}
      <span>{children}</span>
      {rightIcon && <WrapperIcon position="right">{rightIcon}</WrapperIcon>}
    </StyledButton>
  );
};

export default Button;
