/* eslint-disable react/require-default-props */
import React from 'react';
import styled from '@emotion/styled';

import { PreTitle, Title } from '../atoms';
import { dimensions, Theme } from '../../styles/variables';

// const SectionTitle = styled(Title)`
//   text-align: center;
// `;

// const SectionPreTitle = styled(PreTitle)`
//   text-align: center;
// `;

const Header = styled.header`
  text-align: center;
  padding-top: ${dimensions.space.fourtyEight};
`;

type Props = {
  title: string;
  className?: string;
  pretitle: string;
  variant?: Theme;
};

export default function SectionHeader({ title, className, pretitle, variant = 'dark' }: Props) {
  return (
    <Header className={className}>
      <PreTitle variant={variant}>{pretitle}</PreTitle>
      <Title variant={variant}>{title}</Title>
    </Header>
  );
}
