import styled from '@emotion/styled';
import React, { useState } from 'react';

import { breakpoints, dimensions } from '../../styles/variables';
import { PageWrapper, RenderMarkdown } from '../atoms';
import {
  Footer,
  InteractiveMap,
  MapContact,
  MapContactProps,
  SectionHeader,
  Statistic,
  StatisticProps,
} from '../molecules';
import { Region } from '../molecules/InteractiveMap';

const Background = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled(PageWrapper)`
  // height: 100%;
  flex-grow: 1;
`;

const WrapperContent = styled.div`
  padding-top: ${dimensions.space.fourtyEight};
  padding-bottom: ${dimensions.space.fourtyEight};
`;

const Container = styled.div`
  position: relative;

  @media screen and (min-width: 860px) {
    display: flex;
  }
`;

const Statistics = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  > * {
    margin-top: 36px;
  }

  @media screen and (min-width: ${breakpoints.md}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: initial;
  }
`;

const Content = styled.div<{ show: boolean }>`
  transition: all 0.3s ease-in-out;
  position: relative;

  @media screen and (min-width: 860px) {
    flex-basis: 55%;
    opacity: ${({ show }) => (show ? 1 : 0)};
    left: ${({ show }) => (show ? '0' : '55%')};
  }
`;

const StyledInteractiveMap = styled(InteractiveMap)<{ selectedRegion?: Region }>`
  position: relative;
  transition: all 0.3s ease-in-out;

  @media screen and (min-width: 860px) {
    flex-basis: 45%;
    left: ${({ selectedRegion }) => (selectedRegion ? '45%' : '0')};
  }
`;

const Cards = styled.div<{ visible: boolean }>`
  position: absolute;
  top: ${({ visible }) => (visible ? '24%' : '75%')};
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out;
  z-index: ${({ visible }) => (visible ? 0 : -1)};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  min-width: 350px;

  @media screen and (min-width: 860px) {
    top: ${({ visible }) => (visible ? '50%' : '100%')};
    left: 0;
    transform: translate(0, -50%);
    width: 40%;
  }
`;

const Description = styled(RenderMarkdown)`
  margin-top: 48px;
`;

export type MapProps = {
  title: string;
  description: string;
  pretitle: string;
  people: MapContactProps[];
  statistics: StatisticProps[];
};

export default function Map({ title, pretitle, description, people, statistics }: MapProps) {
  const [currentRegion, setCurrentRegion] = useState<Region>();
  const regions = people.reduce(
    (result, p) => ({
      ...result,
      [p.region]: [...(result[p.region] ?? []), p],
    }),
    {} as Record<Region, MapContactProps[]>,
  );

  return (
    <Background>
      <Wrapper>
        <WrapperContent>
          <Container>
            <StyledInteractiveMap selectedRegion={currentRegion} onSelectRegion={setCurrentRegion} regions={regions} />
            {Object.keys(regions).map((region) => (
              <Cards key={region} visible={currentRegion === region}>
                {regions[region].map((person) => (
                  <MapContact key={person.id} {...person} />
                ))}
              </Cards>
            ))}
            <Content show={!currentRegion}>
              <SectionHeader title={title} pretitle={pretitle} />
              <Description>{description}</Description>
            </Content>
          </Container>
          <Statistics>
            {statistics.map((stat) => (
              <Statistic key={stat.id} {...stat} />
            ))}
          </Statistics>
        </WrapperContent>
      </Wrapper>
      <Footer />
    </Background>
  );
}
