import styled from '@emotion/styled';
import React from 'react';
import Img, { FixedObject } from 'gatsby-image';
import { FaLinkedin } from 'react-icons/fa';
import { css } from '@emotion/react';

import { colors, dimensions, fonts, variants } from '../../styles/variables';

export type Image = {
  localFile: {
    childImageSharp: {
      fixed: FixedObject;
    };
  };
};

export type MemberProps = {
  fullname: string;
  function: string;
  location: string;
  linkedin: string;
  image: Image;
  hoverImage?: Image;
};

const Wrapper = styled.div`
  height: 285px;
  width: 230px;
  margin: 20px;

  &:hover .team-alternative-img {
    opacity: 1 !important;
  }

  &:hover .team-base-img {
    opacity: 0 !important;
  }

  &:hover .team-square {
    transform: scale(1.1);
  }

  &:hover .team-function::after {
    background: ${colors.purple};
    width: 50%;
  }
`;

const MemberLabel = styled.div`
  width: 220px;
  background: ${colors.white};
  box-shadow: 0px 0px 5px #62318a57;
  padding: 9px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translate(10px, -10px);
`;

const Fullname = styled.div`
  color: ${colors.black};
  font-size: ${dimensions.text.p}px;
  font-weight: ${variants.bold};
  font: normal normal bold 17px/21px ${fonts.montserrat};
  letter-spacing: 0px;
  position: relative;

  &::after {
    content: '';
    display: block;
    height: 2px;
    width: 0;
    background: transparent;
    transition: width 0.3s ease-in-out;
  }
`;

const Function = styled.div`
  color: ${colors.black};
  font-size: ${dimensions.text.h2}px;
  font-weight: ${variants.regular};
  text-transform: uppercase;
  font: normal normal normal 10px/13px ${fonts.montserrat};
  letter-spacing: 0.55px;
`;

const Square = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
  background-color: ${colors.white};
`;

const CrossFading = styled.div`
  position: relative;
  width: 210px;
  height: 210px;
  z-index: 0;
`;

const imgStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1,
  transition: 'opacity .3s ease',
};

const RoleStack = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 7px;
`;

const Member: React.FC<MemberProps> = ({ function: role, location, fullname, image, hoverImage, linkedin }) => {
  return (
    <Wrapper>
      <CrossFading>
        <Img
          fixed={image.localFile.childImageSharp.fixed}
          imgStyle={{ objectPosition: 'center top' }}
          className="team-base-img"
          style={{
            ...imgStyle,
            opacity: 1,
          }}
        />
        <Img
          fixed={(hoverImage ?? image).localFile.childImageSharp.fixed}
          imgStyle={{ objectPosition: 'center top' }}
          className="team-alternative-img"
          style={{
            ...imgStyle,
            opacity: 0,
          }}
        />
        <Square className="team-square" />
      </CrossFading>
      <MemberLabel>
        <Fullname className="team-function">{fullname}</Fullname>
        <RoleStack>
          <Function>
            {role}
            {location && ` ${location}`}
          </Function>
          <FaLinkedin
            onClick={() => window.open(linkedin)}
            css={css`
              font-size: 16px;
              flex-shrink: 0;
              cursor: pointer;

              &:hover {
                color: ${colors.purple};
              }
            `}
          />
        </RoleStack>
      </MemberLabel>
    </Wrapper>
  );
};

export default Member;
