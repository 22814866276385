import styled from '@emotion/styled';
import React from 'react';
import { dimensions } from '../../styles/variables';

import { PageWrapper } from '../atoms';
import { JoinUs, Member, MemberProps, SectionHeader } from '../molecules';

const Members = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: ${dimensions.space.fourtyEight};
  padding-bottom: ${dimensions.space.fourtyEight};
  margin: 0 auto;
  max-width: 1080px;
`;

export type TeamProps = {
  title: string;
  pretitle: string;
  members: MemberProps[];
};

export default function Team({ title, pretitle, members }: TeamProps) {
  return (
    <PageWrapper>
      <SectionHeader title={title} pretitle={pretitle} />
      <Members>
        {members.map((m) => (
          <Member key={m.fullname} {...m} />
        ))}
        <JoinUs />
      </Members>
    </PageWrapper>
  );
}
