import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      author: string;
      keywords: string;
    };
  };
}

interface Props {
  readonly title?: string;
  readonly description?: string;
}

const Seo: React.FC<Props> = ({ title, description }) => (
  <StaticQuery
    query={graphql`
      query SeoQuery {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => (
      <Helmet
        htmlAttributes={{
          lang: `fr`,
        }}
        title={title ?? data.site.siteMetadata.title}
        titleTemplate={`%s | ${data.site.siteMetadata.title}`}
        meta={[
          {
            name: `description`,
            content: description ?? data.site.siteMetadata.description,
          },
          {
            property: `og:title`,
            content: title ?? data.site.siteMetadata.title,
          },
          {
            property: `og:description`,
            content: description ?? data.site.siteMetadata.description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: data.site.siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: title ?? data.site.siteMetadata.title,
          },
          {
            name: `twitter:description`,
            content: description ?? data.site.siteMetadata.description,
          },
        ]}
      />
    )}
  />
);

export default Seo;
