/* eslint-disable jsx-a11y/media-has-caption */
import styled from '@emotion/styled';
import React from 'react';
import { breakpoints, dimensions } from '../../styles/variables';

type Props = {
  url: string;
  absoluteUrl?: string; // TODO: temporaire pour le build statique !
  mime: string;
};

const HtmlVideo = styled.video<{ mobile?: boolean }>`
  object-fit: cover;
  /* height: 100%;
  width: 100%; */
  left: 0;
  top: 0;
  pointer-events: none;
  width: 100vw;
  z-index: -10;
  position: absolute;
  height: 50vh;
  margin: 0 -${dimensions.space.base};
  @media screen and (min-width: ${breakpoints.md}) {
    position: fixed;
    height: 100vh;
    margin: 0;
  }
`;

const Video: React.FC<Props> = ({ url, absoluteUrl, mime }: Props) => {
  return (
    <HtmlVideo autoPlay muted loop data-keepplaying>
      <source src={absoluteUrl ?? `${process.env.API_URL}${url}`} type={mime} />
    </HtmlVideo>
  );
};

export default Video;
