import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { FaAngleRight } from 'react-icons/fa';

import { colors } from '../../styles/variables';

const Icon = styled(FaAngleRight)`
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  color: ${colors.white};
  display: block;
  flex-shrink: 0;
  padding: 2px;
  font-size: 20px;
  transform: scale(0);
  width: 0;
  transition: all 0.3s ease-in-out;
`;

const Anchor = styled(Link)`
  color: ${colors.white};
  display: flex;
  align-items: center;
  font-size: 21px;
  line-height: 24px;
  padding: 10px 0;
  transition: all 0.3s ease-in-out;

  :hover,
  :focus {
    background-color: rgba(255, 255, 255, 0.1);
    text-decoration: none;

    ${Icon} {
      width: 20px;
      margin-left: 8px;
      margin-right: 8px;
      transform: scale(1);
    }
  }
`;

export default function ProductLink({ children, ...props }: any) {
  return (
    <Anchor {...props}>
      <Icon />
      {children}
    </Anchor>
  );
}
