import * as React from 'react';
import { Global, css } from '@emotion/core';
import styled from '@emotion/styled';

import normalize from '../../styles/normalize';
import { Header, BrokerModal, BrokerProvider } from '../molecules';
import { Seo } from '../atoms';

interface Props {
  className?: string;
  title?: string;
  description?: string;
}

const MainWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Layout: React.FC<Props> = ({ children, ...props }) => (
  <BrokerProvider>
    <BrokerModal />
    <Header />
    <Seo {...props} />
    <Global styles={() => css(normalize)} />
    <MainWrapper>{children}</MainWrapper>
  </BrokerProvider>
);

export default Layout;
